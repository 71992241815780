<template>
  <div class="lg:flex lg:h-screen lg:justify-center lg:items-center">
    <button style="top: -1.5rem;" class="absolute top-0 right-1 lg:right-5 text-6xl text-white" tabindex="0" aria-label="Close lightbox." onclick="closeLightbox()" onkeydown="return event.keyCode != 13 || closeLightbox(this)">&times;</button>
    <div v-if="view" class="max-w-screen-xl">
      <div class="lg:flex bg-white lg:rounded-lg overflow-hidden popover">
        <div class="lg:w-auto lg:flex-grow" style="height: auto">
          <video v-if="video" controls class="h-full mx-auto">
            <source :src="video" type="video/mp4">
            Your browser does not support the video tag.
          </video>
          <!--
          <img v-else :src="image" class="h-full mx-auto"/>
          <div v-else style="width: 100%; height: 100%; __display: flex;">
            <div style="__margin: auto auto; width: 100%; height: 100%;" :style="'background-image: ' + image">
              <img :src="image" style="max-width:100%; max-height:100%; height: auto; width: auto; margin-top: auto; margin-bottom: auto;"/>
            </div>
          </div>
          -->
          <div v-else style="height: 100%; background-color: #f6f6f7; background-size: contain; background-position: center center; background-repeat: no-repeat;" :style="{backgroundImage:`url(${image})`}">
            <img :src="image" class="lg:hidden"/>
          </div>

        </div>
        <div class="lg:flex-none lg:w-2/5 lg:flex-col lg:justify-between p-6 lg:p-12 h-100 lg:overflow-y-auto">
          <div class="flex items-center mb-6 lg:mb-12">
            <div class="mr-5">
              <img v-if="avatar" :src="avatar + '&width=150&height=150&quality=60'" class="rounded-full shadow bg-gray-100 w-8 h-8"/>
              <img v-else :src="'https://app.news.travel/v1/avatars/initials?project=60e70fa53301e&name=' + property.name" class="inline-block w-8 h-8 bg-gray-300 rounded-full shadow"/>
            </div>
            <div class="leading-4 text-sm">
              <div class="truncate">{{property.name}}</div>
              <span class="opacity-50">
                <time-ago :refresh="60" :datetime="story.createdAt" long></time-ago>
              </span>
            </div>
          </div>
          <div class="">
            <h1 class="font-semibold text-lg mb-1">{{story.title}}</h1>
            <div v-html="parify( story.text )"></div>
            <a href="" v-for="tag in story.tags" :key="tag" class="inline-block underline mr-1">#{{tag}}</a>

            <a :href="story.callToActionLink" v-if="!(story.callToAction === 'None')" class="block rounded-md mt-6 px-4 py-3 bg-gray-600 text-white text-center text-base font-medium w-full shadow-sm hover:bg-gray-800 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-300">
              {{story.callToAction}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api'
import { Server } from '../utils/config'
import { TimeAgo } from 'vue2-timeago'

export default {
  name: 'Story',
  components: {
    TimeAgo
  },
  data () {
    return {
      view: true,
      story: {},
      property: {},
      video: false,
      image: false,
      avatar: null
    }
  },
  async mounted () {
    this.story = await api.getDocument(Server.postsCollectionID, this.$route.params.story_id)
    this.image = api.fetchImage(this.story.image)
    if (this.story.video) {
      this.file = await api.fetchFile(this.story.video)
      this.video = api.downloadFile(this.file.$id)
    }
    this.property = await api.getDocument(Server.propertiesCollectionID, this.story.propertyId)
    if (this.property.avatarId) {
      this.avatar = api.fetchImage(this.property.avatarId)
    }
  },
  methods: {
    convertDate (epoch) {
      var date = new Date(epoch)
      return date.toLocaleString()
    },
    parify (string) {
      // string = string.replace(/(?:\r\n|\r|\n)/g, '<br>')
      return string
    }
  }
}
</script>

<style>
  .popover {
    height: 100vh;
    overflow: scroll;
  }
  @media (min-width: 1024px) {
    .popover {
      height: 70vh
    }
  }
</style>
